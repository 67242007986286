<template>
<div class="content-container">
<div> 
	<h5>About Me</h5>  
</div>
<div> 
<p>
	Hello and welcome! I'm Santiago Figueroa, a bilingual Software Developer fluent in English and Spanish, with an insatiable curiosity and unwavering passion for my field. 
	Apart from the digital realm, I am also a dedicated practitioner of Kendo, a traditional Japanese martial art that instills discipline, patience, and strategic thinking—qualities that significantly influence my approach to software development.
</p>
</div>

<div> 
<p>
	Despite my substantial experience in the tech world, I consider myself a student. The technological landscape never stops evolving, and I'm perpetually eager to immerse myself in new learning opportunities. 
	I firmly believe that continuous learning is the foundation of innovation and success.
</p>

</div>
<div>
	<p>
		I appreciate the time you've taken to visit my website. 
		Whether you're an old friend, a potential collaborator, or a recruiter considering my profile, your time and interest are genuinely valued.
	</p>
</div>
<div>
	<p>
		Here, you'll get a glimpse into my world: my projects, my aspirations, and the skills I continually hone. This site is not just a showcase of my portfolio; it's a reflection of my journey in software development. 
		The principles I've acquired from Kendo—precision, patience, and strategy—are mirrored in every coding challenge I undertake.
	</p>
</div>
<div>
	<p>I hope that as you explore, you'll gain insight into not only my professional abilities but also my passion for software development and my unique approach imbued with the lessons from Kendo. For me, software development is not merely a job—it's a commitment to building digital solutions that are intuitive, efficient, and make a real impact.</p>
</div>
<div>
	<p>Thank you once again for your time. Enjoy your visit, and feel free to reach out with any questions or if you're interested in collaborating. I'm always ready to engage in new projects and embrace fresh challenges.</p>
</div>
<div>
	<p>
		Sincerely,
Santiago Figueroa
	</p>
</div>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>