import axios from "axios";

class ApiService {
  constructor() {
    this.API_URL = this.getApiUrl(); // Get API URL based on environment
  }

  getApiUrl() {
    if (process.env.NODE_ENV === "production") {
      return "https://api.santiagofigueroa.com/api/"; // Production API URL
    } else {
      return "http://localhost:5002/api/"; // Development API URL
    }
  }

  async loadAllUsersView() {
    let result;
    await axios
      .get(this.API_URL + "users")
      .then(res => {
        result = res.data.groups;
        console.log(result); // See some results from the outbound request
      });
    return result;
  }

  async postEmail(emailForm) {
    console.log(emailForm);
    try {
      const res = await axios.post(this.API_URL + "form", emailForm);
      console.log(res);
      if (res.status === 200) return res.data;
      return null;
    } catch (error) {
      console.error("Error sending email:", error.response ? error.response.data : error.message);
      throw error;
    }
  }
}

export default new ApiService();
