
<template id="navBar">  
<div class="container">
  
  <nav class="navbar navbar-expand-sm navbar-dark fixed-top" toggleable="lg" type="light" id="mainNav" v-bind:class=" { 'navbarOpen': show }">
   <a class="navbar-brand js-scroll-trigger" href="/home">ASF</a>
   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#nav-collapse"  aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" @click.stop="toggleNavbar()">
     <span class="navbar-toggler-icon"></span>
   </button>
 
   <div class="collapse navbar-collapse" id="nav-collapse" v-bind:class="{ 'show': show }">
     <ul class="navbar-nav ml-auto">
       <li class="nav-item active">
         <a class="nav-link" href="/home">
           <i class="fa fa-home"></i>
           Home
           <span class="sr-only">(current)</span>
           </a>
       </li>
       <li class="nav-item">
         <a class="nav-link" href="/about">
           About me 
         </a>
       </li>
	   <li class="nav-item">
         <a class="nav-link" href="/experience">
           Experience
         </a>
       </li>
	   <li class="nav-item">
         <a class="nav-link" href="/contact">
           Contact me
         </a>
       </li>
    
     </ul>
   </div>
 </nav>
  
  </div> 
  <!--container end.//-->
  
</template>

<script>




export default {
  name: 'navBar',
  props: {
      customContent:{
          type: String,
          default: 'default Content'
      },
  },
  data() {
    return {
      show: true
    }
  },
  methods: {
    toggleNavbar() {
      this.show = !this.show
    }
  }
};
</script>
