<template>
    <div class="content-container">

        <div>
            <h2> Schedule call ? </h2>
            <p>
                If you want to schedule a 15 minute call with me, requested using by sending me an email.
                I only answer preschedule calls, as I get really stingy with my time, no flacking please.
            </p>
            <form v-on:submit.prevent="submitForm">
                <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" class="form-control" id="name" placeholder="Your name" v-model="form.name">
                </div>
                <div class="form-group">
                    <label for="email">Email address</label>
                    <input type="email" class="form-control" id="email" placeholder="name@example.com" v-model="form.email">
                </div>
                <div class="form-group">
                    <label for="referrer">From where did you hear about me ?</label>
                    <select name="referrer" class="form-control" id="referrer" v-model="form.referrer">
                        <option value="Website">Website</option>
                        <option value="Friend">Friend</option>
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="profession">You are a : </label>
                    <select name="profession" class="form-control" id="profession" v-model="form.profession">
                        <option value="Developer">Developer</option>
                        <option value="HR">HR</option>
                        <option value="Recruiter">Recruiter</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="formControlRange"> What are you interested in talkng about ? </label><br />
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="interestedOption" id="inlineRadio1"
                            value="opportunity" v-model="form.interested">
                        <label class="form-check-label" for="inlineRadio1">New Opportunity</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="interestedOption" id="inlineRadio2" value="scheduleCall"
                            v-model="form.interested">
                        <label class="form-check-label" for="inlineRadio2">Schedule Call</label>
                    </div>
                </div>
                <div class="form-group">
                    <label for="message">Your message</label>
                    <textarea name="message" class="form-control" id="message" rows="3" v-model="form.message"></textarea>
                </div>
                <div class="form-group">
                    <label for="satisfaction">What would you rate my Personal Web Page? </label>
                    <input type="range" name="satisfaction" class="form-control-range" id="formControlRange" min="0"
                    max="10" v-model="form.satisfaction">
                    <span>{{ form.satisfaction }}</span>
                </div>

                <div class="form-group">
                    <button class="btn btn-primary">Submit</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
//import axios from 'axios';
//import store from '../store'; TODO: Implement for loading in the future.
import API from "../API/api";

export default {
    name: 'PostFormAxios',
    data() {
        return {
            form: {
                name: '',
                email: '',
                referrer: '',
                profession: [],
                message: '',
                satisfaction: '5',
                interested: ''
            }
        }
    },
    methods: {
        submitForm() {
            console.log(this.form);
            API.postEmail(this.form)
                .then((res) => {
                    //Perform Success Action
                    console.log(res);
                    //
                })
                .catch((error) => {
                    // error.response.status Check status code
                    console.log(error);
                    // 
                }).finally(() => {
                    //Perform action in always
                    this.resetForm();
                    alert("Your email has been send!");
                });
        },
        resetForm() {
            this.form = {
                name: '',
                email: '',
                referrer: '',
                profession: [],
                message: '',
                satisfaction: '5',
                interested: ''
            };
        }
    }
}
</script>
