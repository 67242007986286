<template>
<div id="app">
	<Navbar/>
	<section class="page-section bg-light" id="portfolio">
		<div class="container">
			<div class="row">
				<div class="col-md-9">
					<h2 class="section-heading text-uppercase"></h2>
					<router-view/>
				</div>
			</div>
		</div>
	</section>
	<div class="row">
		<div class="col-sm-9 col-md-9 col-lg-9 col-xs-9">
			<footer class="footer py-4 navbar-fixed-bottom"> Santiago Figueroa Personal Web Page</footer>
		</div>
		<div class="col-sm-3 col-md-3 col-lg-3 col-xs-3">
			<span id="siteseal" class="py-4 navbar-fixed-bottom">
			</span>
		</div>
		</div>
</div> 
</template>

<script>
import Navbar from './components/Navbar.vue'


export default {
  components: { Navbar },
  name: 'App',
}
</script>

<style>
</style>
