<template>
    <div class="content-container">

        <h2>MediaSterling</h2>
        <h3>November 2022 - Present</h3>
        <h4>Full Stack Developer</h4>
        <p>
            Currently working at MediaSterling as a Full-stack software developer, delivering Equity research software for
            Investment Banking institutions.
            The software is built using C# (Net framework 4.5), MVC architecture, MySQL, HTML5, CSS3, and Bootstrap.
        </p>
        <b>Skills developed</b>
        <ul>
            <li>
                <b>Equity Research</b> - Further developed knowledge in Equity research software.
            </li>
        </ul>

        <br />
        <h2>Panmure Gordon</h2>
        <h3>September 2021 - November 2022</h3>
        <h4>Software Developer</h4>
        <p>
            Worked at Panmure Gordon PLC, modernizing applications to a Web base environment using the latest Microsoft
            Packages with .NET core 6 and Angular 6 for front-end development.
        </p>
        <b>Skills developed</b>
        <ul>
            <li>
                <b>Trading</b> - Gained experience using Fidessa trading portal.
            </li>
            <li>
                <b>Time Management</b> - Developed effective time management skills due to the long work hours and
                various application running times.
            </li>
        </ul>

        <br />
        <h2>Sherpr Global Ltd</h2>
        <h3>April 2021 - July 2021</h3>
        <h4>C# Full-Stack Developer (Product owner/Contractor)</h4>
        <p>
            Took charge of all Tech in the company from the server side to the development of new features, in our ordering
            management system using Vue-JS, .NET core 3.1 and MySQL.
        </p>
        <b>Skills developed</b>
        <ul>
            <li>
                <b>Vue JS</b> - Added new segments of data to the Order management system.
            </li>
            <li>
                <b>CTO / Ownership</b> - Took control of all aspects of the business in all technology.
            </li>
        </ul>

        <br />
        <h2>Trail Finders</h2>
        <h3>October 2019 - April 2021</h3>
        <h4>Full-stack Software Developer</h4>
        <p>
            Worked as a Software developer developing and maintaining a multithread booking systems using UniBasic/BASIC and
            MySQL 5.7.
        </p>
        <b>Skills developed</b>
        <ul>
            <li>
                <b>MySQL</b> - Developed skills in writing queries for extracting, adding, and deleting data.
            </li>
            <li>
                <b>Concentration</b> - Developed high concentration abilities due to working 45 hours a week.
            </li>
        </ul>

        <br />
        <h2>Capacitas LTD</h2>
        <h3>April 2019 - June 2019</h3>
        <h4>C# Full-Stack Developer</h4>
        <p>
            Worked in the software development team of Capacitas, designed and developed new features for performance
            testing for of all out client’s servers metrics.
        </p>
        <b>Skills Developed</b>
        <ul>
            <li>
                <b>Fast delivery</b> - Delivered new features in a two week sprint.
            </li>
        </ul>

        <br />
        <h2>Teach First</h2>
        <h3>October 2017 - April 2019</h3>
        <h4>Junior .NET Developer</h4>
        <p>
            Worked in the .NET development team at Teach First, designing the front-end and Back-end of all
            application forms using C#, Apex, and Salesforce.
        </p>
        <b>Skills developed</b>
        <ul>
            <li>
                <b>Responsive web design</b> - Developed responsive web design skills using bootstrap and CSS3 for all
                Teach First application forms.
            </li>
            <li>
                <b>MVC</b> - Developed skills in MVC (Model View Controller) framework.
            </li>
        </ul>

    </div>
</template>
<script>
export default {

}
</script>

<style></style>