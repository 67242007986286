<template>
<div class="jumbotron">
    <div class="masthead-heading text-uppercase">Santiago Figueroa</div>
    <p>
        Welcome to my website.
    </p>
   
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>