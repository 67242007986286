import Home from '../Views/Home.vue';
import About from '../Views/About.vue';
import Experience from '../Views/Experience.vue';
import Contact from '../Views/Contact.vue';
import Countdown from '../Views/Countdown.vue';  // TODO: take out Just for fun
import VueRouter from 'vue-router';



const routes = [
  {
    path: '/',
    component: Home,
},
    {
          path: '/home',
          component: Home,
    },
    {
      path: '/about',
      component: About,
    },   
    {
      path: '/experience',
      component: Experience,
    },   
    {
      path: '/contact',
      component: Contact,
    },
    {
      path: '/countdown',
      component: Countdown,
    },
   
  ];
  
  
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL, 
    routes,
  });
  
  
  export default router;
  