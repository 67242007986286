<template>
  <div id="app">
    <h1>Sober Countdown</h1>
    <div v-if="timeRemaining > 0">
      <p class="countdown">Days: <span class="number">{{ days }}</span></p>
      <p class="countdown">Hours: <span class="number">{{ hours }}</span></p>
      <p class="countdown">Minutes: <span class="number">{{ minutes }}</span></p>
      <p class="countdown">Seconds: <span class="number">{{ seconds }}</span></p>
    </div>
    <div v-else>
      <p>One pint and cut !</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      targetDate: new Date("2023-09-21T00:00:00"),
      timeRemaining: null,
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
    };
  },
  created() {
    this.updateCountdown();
    setInterval(() => {
      this.updateCountdown();
    }, 1000);
  },
  methods: {
    updateCountdown() {
      const now = new Date();
      this.timeRemaining = this.targetDate - now;

      if (this.timeRemaining > 0) {
        this.days = Math.floor(this.timeRemaining / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((this.timeRemaining / (1000 * 60 * 60)) % 24);
        this.minutes = Math.floor((this.timeRemaining / 1000 / 60) % 60);
        this.seconds = Math.floor((this.timeRemaining / 1000) % 60);
      } else {
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
      }
    },
  },
};
</script>

<style scoped>
#app {
  text-align: center;
  color: red;
  background-color: black;
}

h1 {
  font-size: 2.5em;
}

.countdown {
  font-size: 1.5em;
  font-family: 'Courier New', Courier, monospace; /* this is a common monospace font */
}

.number {
  font-weight: bold;
  font-size: 2em;
}
</style>
